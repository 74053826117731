import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de-CH';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import {
	FakeMissingTranslationHandler,
	MissingTranslationHandler,
	TranslateCompiler,
	TranslateDefaultParser,
	TranslateFakeCompiler,
	TranslateFakeLoader,
	TranslateLoader,
	TranslateModule,
	TranslateParser,
	TranslateService,
	TranslateStore,
	USE_DEFAULT_LANG,
	USE_STORE,
} from '@ngx-translate/core';
import { config } from './config';
import { TranslatableDirective } from './translatable.directive';
import { TranslatablePipe } from './translateable.pipe';

registerLocaleData(localeDe, 'de');

@NgModule({
	declarations: [TranslatablePipe, TranslatableDirective],
	imports: [HttpClientModule, TranslateModule.forRoot(config)],
	exports: [TranslateModule, TranslatableDirective],
})
export class I18nModule {
	constructor(private translateService: TranslateService) {
		this.translateService.use('de');
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: I18nModule,
			providers: [
				{ provide: LOCALE_ID, useValue: 'de' },
				config.loader || {
					provide: TranslateLoader,
					useClass: TranslateFakeLoader,
				},
				config.compiler || {
					provide: TranslateCompiler,
					useClass: TranslateFakeCompiler,
				},
				config.parser || {
					provide: TranslateParser,
					useClass: TranslateDefaultParser,
				},
				config.missingTranslationHandler || {
					provide: MissingTranslationHandler,
					useClass: FakeMissingTranslationHandler,
				},
				TranslateStore,
				{ provide: USE_STORE, useValue: config.isolate },
				{ provide: USE_DEFAULT_LANG, useValue: config.useDefaultLang },
				TranslateService,
			],
		};
	}
}

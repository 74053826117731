import { Environment } from './environment.interface';

export const environment: Environment = {
	production: false,
	firebase: {
		apiKey: 'AIzaSyDDWcb5Eid_Hz5MzehHfoi-quqyIPDNNgE',
		authDomain: 'dercampus-dev.firebaseapp.com',
		databaseURL: 'https://dercampus-dev.firebaseio.com',
		projectId: 'dercampus-dev',
		storageBucket: 'dercampus-dev.appspot.com',
		messagingSenderId: '443851969317',
	},
	stripe: 'pk_test_6A6fzvaTnSwFmqBbiGDDV8JK',
};

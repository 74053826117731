import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule as NgRxStoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../../environments/environment';
import { metaReducers, reducers } from './reducers';

@NgModule({
	imports: [
		NgRxStoreModule.forRoot(reducers, { metaReducers }),
		StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
		EffectsModule.forRoot([]),
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
	],
	// providers: [
	// 	{
	// 		provide: RouterStateSerializer,
	// 		useClass: CustomRouterStateSerializer,
	// 	},
	// ],
})
export class StoreModule {}

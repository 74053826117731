import { AngularFirestore, } from '@angular/fire/firestore';
import { of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
var DatabaseService = /** @class */ (function () {
    function DatabaseService(firestore) {
        this.firestore = firestore;
    }
    DatabaseService.collectionWithReference = function () {
        return map(function (actions) {
            return actions.map(function (_a) {
                var payload = _a.payload;
                return ({
                    ref: payload.doc.ref,
                    data: payload.doc.data(),
                });
            });
        });
    };
    DatabaseService.prototype.loadDocument = function (path) {
        return this.firestore
            .doc(path)
            .snapshotChanges()
            .pipe(map(function (_a) {
            var payload = _a.payload;
            return payload;
        }), switchMap(function (snap) {
            return snap.exists ? of(snap) : throwError(new Error('Model not existing'));
        }), map(function (snap) { return ({
            ref: snap.ref,
            data: snap.data(),
        }); }));
    };
    // TODO Make DRY
    DatabaseService.prototype.loadCollection = function (path, queryFn) {
        return this.firestore
            .collection(path, queryFn)
            .snapshotChanges()
            .pipe(map(function (actions) {
            return actions.map(function (_a) {
                var payload = _a.payload;
                return ({
                    ref: payload.doc.ref,
                    data: payload.doc.data(),
                });
            });
        }));
    };
    DatabaseService.prototype.addDocument = function (collectionPath, data) {
        var collection = this.firestore.collection(collectionPath);
        return collection.add(data);
    };
    DatabaseService.prototype.updateDocument = function (documentPath, update) {
        return this.firestore.doc(documentPath).update(update);
    };
    DatabaseService.prototype.streamDocument = function (path) {
        return this.firestore.doc(path).valueChanges();
    };
    return DatabaseService;
}());
export { DatabaseService };

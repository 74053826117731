<mat-toolbar color="primary">
  <img class="logo" alt="dercampus Logo" [src]="logoSrc">

  <div class="actions" [ngSwitch]="view">

    <ng-container *ngSwitchCase="'CourseComponent'">
      <mat-icon>search</mat-icon>
      <mat-icon>account_circle</mat-icon>
    </ng-container>

<!--    <ng-container *ngSwitchCase="'CrashCourseComponent'">-->
<!--          <button-->
<!--            mat-button-->
<!--            (click)="scrollToEvents()"-->
<!--          >{{ 'crashCourse.events.register' | translate }}</button>-->

<!--    </ng-container>-->

  </div>
</mat-toolbar>

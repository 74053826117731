import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgwWowModule } from 'ngx-wow';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { I18nModule } from './i18n/i18n.module';
import { TopBarModule } from './shared/top-bar/top-bar.module';
import { RouterModule } from '@angular/router';
import { AppShellComponent } from './app-shell/app-shell.component';

@NgModule({
	declarations: [AppComponent, AppShellComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		CoreModule,
		I18nModule.forRoot(),
		NgwWowModule,
		AppRoutingModule,
		MatToolbarModule,
		TopBarModule,
		RouterModule,
	],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor() {}
}

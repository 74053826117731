import { User } from '@models/crash-course';
import { Action } from '@ngrx/store';

export enum AuthActionTypes {
	LoadUser = '[Auth] Load User',
	UserLoaded = '[Auth] Loaded',
	UpdateUser = '[Auth] Update User',
	UserDataUpdated = '[Auth] User updated',
}

export class LoadUser implements Action {
	readonly type = AuthActionTypes.LoadUser;
}

export class UserLoaded implements Action {
	readonly type = AuthActionTypes.UserLoaded;
}

export class UpdateUser implements Action {
	readonly type = AuthActionTypes.UpdateUser;
}

export class UserDataUpdated implements Action {
	readonly type = AuthActionTypes.UserDataUpdated;

	constructor(public payload: User) {}
}

export type AuthActions = LoadUser | UserLoaded | UpdateUser | UserDataUpdated;

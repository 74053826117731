import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
	MatButtonModule,
	MatDialogModule,
	MatInputModule,
} from '@angular/material';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { UserFormComponent } from './components/user-form/user-form.component';
import { AuthService } from './service/auth.service';
import { authReducer } from './store/reducer';

const MATERIAL_MODULES = [MatDialogModule, MatInputModule, MatButtonModule];

@NgModule({
	declarations: [UserFormComponent],
	entryComponents: [UserFormComponent],
	imports: [
		CommonModule,
		StoreModule.forFeature('auth', authReducer),
		ReactiveFormsModule,
		TranslateModule,
		...MATERIAL_MODULES,
	],
	providers: [AuthService],
})
export class AuthModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatToolbarModule } from '@angular/material';
import { TopBarComponent } from './top-bar.component';

const MATERIAL_MODULES = [MatToolbarModule, MatIconModule];

@NgModule({
	declarations: [TopBarComponent],
	imports: [CommonModule, ...MATERIAL_MODULES],
	exports: [TopBarComponent],
})
export class TopBarModule {}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UserFormData } from './user-form';

@Component({
	selector: 'dc-user-form',
	templateUrl: './user-form.component.html',
	styleUrls: ['./user-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent {
	form: FormGroup;
	buttonLabel: string;

	constructor(
		private dialog: MatDialogRef<UserFormComponent, UserFormData>,
		@Inject(MAT_DIALOG_DATA)
		data: {
			user: UserFormData;
			buttonLabel?: string;
		},
	) {
		this.createForm(data.user);
		if (data.buttonLabel) {
			this.buttonLabel = data.buttonLabel;
		}
		// switch (props.state) {
		// 	case RegistrationState.CONFIRMED:
		// 		this.buttonLabel = 'crashCourse.checkout.pay';
		// 		return;
		// 	case RegistrationState.WAITING_LIST:
		// 		this.buttonLabel = 'crashCourse.events.waitingList';
		// 		return;
		// }
	}

	private createForm(attendee?: UserFormData): void {
		const controls: { [Key in keyof UserFormData]: FormControl } = {
			firstName: new FormControl(
				attendee && attendee.firstName,
				Validators.required,
			),
			lastName: new FormControl(
				attendee && attendee.lastName,
				Validators.required,
			),
			email: new FormControl(attendee && attendee.email, [
				Validators.required,
				Validators.email,
			]),
		};
		this.form = new FormGroup(controls);
	}

	onSubmit(): void {
		const data = this.form.value;
		this.dialog.close(data);
	}
}

<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>

  <mat-form-field>
    <input
      matInput
      type="text"
      [placeholder]="'crashCourse.checkout.firstName' | translate"
      formControlName="firstName"
    >
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      type="text"
      [placeholder]="'crashCourse.checkout.lastName' | translate"
      formControlName="lastName"
    >
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'crashCourse.checkout.emailLabel' | translate }}</mat-label>
    <input
      matInput
      type="email"
      [placeholder]="'crashCourse.checkout.email' | translate"
      formControlName="email"
    >
  </mat-form-field>

  <mat-dialog-actions align="end">
    <button
      mat-button
      color="accent"
      type="submit"
      [disabled]="form.invalid"
    >{{ buttonLabel | translate }}</button>
  </mat-dialog-actions>

</form>



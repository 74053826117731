import { Injectable } from '@angular/core';
import {
	AngularFirestore,
	DocumentChangeAction,
	DocumentReference,
	QueryFn,
} from '@angular/fire/firestore';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FireStoreDocument } from './fire-store-document';

@Injectable()
export class DatabaseService {
	constructor(private firestore: AngularFirestore) {}

	static collectionWithReference<T>(): any {
		return map((actions: DocumentChangeAction<T>[]) =>
			actions.map<FireStoreDocument<T>>(({ payload }) => ({
				ref: payload.doc.ref,
				data: payload.doc.data(),
			})),
		);
	}

	loadDocument<T>(path: string): Observable<FireStoreDocument<T>> {
		return this.firestore
			.doc<T>(path)
			.snapshotChanges()
			.pipe(
				map(({ payload }) => payload),
				switchMap((snap) =>
					snap.exists ? of(snap) : throwError(new Error('Model not existing')),
				),
				map((snap) => ({
					ref: snap.ref,
					data: snap.data(),
				})),
			);
	}

	// TODO Make DRY
	loadCollection<T>(
		path: string,
		queryFn?: QueryFn,
	): Observable<FireStoreDocument<T>[]> {
		return this.firestore
			.collection<T>(path, queryFn)
			.snapshotChanges()
			.pipe(
				map((actions) =>
					actions.map(({ payload }) => ({
						ref: payload.doc.ref,
						data: payload.doc.data(),
					})),
				),
			);
	}

	addDocument<T>(collectionPath: string, data: T): Promise<DocumentReference> {
		const collection = this.firestore.collection<T>(collectionPath);
		return collection.add(data);
	}

	updateDocument<T>(documentPath: string, update: Partial<T>): Promise<void> {
		return this.firestore.doc<T>(documentPath).update(update);
	}

	streamDocument<T>(path: string): Observable<T> {
		return this.firestore.doc<T>(path).valueChanges();
	}
}

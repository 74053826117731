import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgwWowService } from 'ngx-wow';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { slidingAnimation } from './animations/sliding';

declare let ga: Function;

@Component({
	selector: 'dc-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	animations: [slidingAnimation],
})
export class AppComponent {
	view: string;
	items$ = new BehaviorSubject([]);

	constructor(private router: Router, private wowService: NgwWowService) {
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				if (environment.production) {
					ga('set', 'page', event.urlAfterRedirects);
					ga('send', 'pageview');
				}
				this.wowService.init();
			});
	}

	onRouterOutletActivate(component: any): void {
		this.view = component.constructor.name;
	}
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { User } from '@models/crash-course';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import { AppState } from '../../store/reducers';
import { UserFormComponent } from '../components/user-form/user-form.component';
import { UserDataUpdated } from '../store/actions';
import { selectUserData } from '../store/selectors';

@Injectable()
export class AuthService {
	userData$: Observable<User> = this.store.select(selectUserData);

	constructor(private store: Store<AppState>, private dialog: MatDialog) {
		// init user from fireBase here;
	}

	collectUserData(buttonLabel?: string): Observable<User> {
		return this.userData$.pipe(
			take(1),
			switchMap((user) =>
				this.dialog
					.open(UserFormComponent, { data: { user, buttonLabel } })
					.afterClosed(),
			),
			filter(Boolean),
			tap((userData) => this.store.dispatch(new UserDataUpdated(userData))),
			switchMapTo(this.userData$),
			filter(Boolean),
		);
	}
}

import { User } from '@models/crash-course';
import { AuthActions, AuthActionTypes } from './actions';

export interface AuthState {
	auth: any;
	userData: User;
}

export const initialState: AuthState = {
	auth: null,
	userData: null,
};

export function authReducer(
	state = initialState,
	action: AuthActions,
): AuthState {
	switch (action.type) {
		case AuthActionTypes.UserDataUpdated:
			return {
				...state,
				userData: action.payload,
			};
	}
	return state;
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		loadChildren: './landing/landing.module#LandingModule',
	},
	{
		path: 'course',
		loadChildren: './course/course.module#CourseModule',
	},
	{
		path: 'crashkurs',
		loadChildren: './crash-course/crash-course.module#CrashCourseModule',
	},
	{
		path: 'crashcourse',
		loadChildren: './crash-course/crash-course.module#CrashCourseModule',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}

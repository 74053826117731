import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/service/auth.service';
import { DatabaseService } from './database/database.service';
import { StoreModule } from './store/store.module';

@NgModule({
	imports: [
		StoreModule,
		AngularFireModule.initializeApp(environment.firebase),
		AngularFirestoreModule,
		AngularFireStorageModule,
		AuthModule,
	],
	providers: [DatabaseService, AuthService],
})
export class CoreModule {}

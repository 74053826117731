import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const CUSTOM_LOADER = {
	provide: TranslateLoader,
	useFactory: HttpLoaderFactory,
	deps: [HttpClient],
};

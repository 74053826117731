/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shared/top-bar/top-bar.component.ngfactory";
import * as i3 from "./shared/top-bar/top-bar.component";
import * as i4 from "@angular/router";
import * as i5 from "./app.component";
import * as i6 from "ngx-wow";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "sliding", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateY(-100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateY(0px)" }, offset: null }, timings: 1000 }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dc-top-bar", [], [[24, "@sliding", 0]], null, null, i2.View_TopBarComponent_0, i2.RenderType_TopBarComponent)), i1.ɵdid(1, 49152, null, 0, i3.TopBarComponent, [], { view: [0, "view"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, [[null, "activate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activate" === en)) {
        var pd_0 = (_co.onRouterOutletActivate($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, { activateEvents: "activate" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.view; _ck(_v, 1, 0, currVal_1); _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = "on"; _ck(_v, 0, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dc-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i5.AppComponent, [i4.Router, i6.NgwWowService], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("dc-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'dc-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent {
	logoSrc = './assets/images/logo/white.png';
	@Input() view: string;
}
